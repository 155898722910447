/* --------- Setting Css ---------  */

.divdrmb.ant-divider-horizontal.ant-divider-with-text {
  margin: 1px !important;
}

.inputboxform_mb.ant-form-item {
  margin: 15px !important;
}

/*--------- Setting Tabs ---------*/
.Tab-setting .ant-tabs-nav::before {
  border-bottom: none;
}

.Tab-setting .ant-tabs-nav {
  margin: 0px !important;
}

/* --------- Setting Upload Client Image ---------*/
.upload-setting .ant-upload {
  width: 64px !important;
  height: 64px !important;
  background-color: aliceblue;
  border-radius: 100%;
}

/* --------- Setting companyInfo ---------*/
.input-setting-companyInfo .ant-form-item-label>label {
  width: 150px !important;
}

.input-setting-companyInfo {
  margin-bottom: 10px !important;
}

/*--------- Setting Customization ---------*/
.input-setting-customization .ant-form-item-label>label {
  width: 50px !important;
}

.input-setting-customization.ant-form-item {
  margin-bottom: 0px !important;
}

/* --------- Setting Customization ---------*/
.input-setting-customization.ant-form-item {
  margin-bottom: 0px !important;
}

/*--------- Setting customization card  ---------*/
.card-setting .ant-card-body {
  padding: 0px 16px !important;
}

.cardBody .ant-card-body {
  padding: 6px !important;
}

/* --------- Setting customization card header ---------*/
.card-setting .ant-collapse-header .ant-collapse-header-text {
  color: #0b308e;
  font-size: 16px;
  font-weight: 600;
}

.collapseHeader .ant-collapse-header .ant-collapse-header-text {
  color: #0b308e;
  font-size: 15px;
  font-weight: bold;
}

/* --------- Setting Vat ---------*/
.input-setting-vat.ant-form-item {
  margin-bottom: 10px !important;
}

/*--------- Setting PaymentTerm ---------*/
.input-setting-paymentTerm.ant-form-item {
  margin-bottom: 10px !important;
}

/*--------- Setting Report ---------*/
.divider-setting {
  margin: 0px 0px 25px 0px;
}

/* .input-setting-report.ant-form-item {
  margin-bottom: 10px !important;
} */

.ant-collapse-header-text {
  color: black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 4px;
}

.custom-select.ant-select-disabled .ant-select-selector {
  color: #807a7a !important;
  font-size: 14px !important;
  /* Use a darker color */
}

.custom-input.ant-input[disabled] {
  color: #807a7a !important;
  /* font-size: 14px !important; */
  /* Use a darker color */
}

.small-font-select .ant-select-selector .ant-select-selection-item {
  font-size: 13px;
  /* Adjust the font size as needed */
}

.small-font-input {
  font-size: 13px;
  /* Adjust the font size as needed */
}


.box {
  background-color: #f0f0f0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  width: 100%;
}

.box:hover {
  transform: translateY(-5px);
}

.bigbox {
  background-color: #e0e0e0;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  height: 200px;
}

.bigbox:hover {
  transform: translateY(-8px);
}