@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Resp-AmtFont {
    font-size: 15px;
  }
  .Resp-Tital {
    font-size: 10.5px;
  }
  .Resp-Img {
    width: 30px !important;
    height: 30px !important;
    margin-left: 0px !important;
  }
  .cusomcard.ant-card .ant-card-body {
    padding: 12px !important;
  }
  .cusomcard.ant-card:not(.ant-card-bordered) {
    height: 103px !important;
  }
  .Resp-Icons {
    margin-left: 10px !important;
  }
  .Resp-signs {
    margin-top: 50px !important;
    height: 20px !important;
    width: 20px !important;
  }
  .EchartDisplay {
    width: 70px !important;
    margin-top: -35px !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1132px) {
  .EchartDisplay {
    margin-left: -18px !important;
    width: 110px !important;
  }
  .TotalSalesGraph {
    margin-left: 35px !important;
  }
  .Tabs-Responsive {
    background-color: red !important;
    margin-top: 55px !important;
  }
}
.Tabs-Responsive {
  background-color: #f5f8ff !important;
  font-weight: 500 !important;
  width: 100% !important;
  margin-top: 8px !important;
}
.customTab .ant-tabs-nav .ant-tabs-tab-active {
  background-color: whitesmoke !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  z-index: 1;
  margin-left: 15px;
}
.chartStyle {
  
  padding: 0px 10px;
  height: 100%;
  max-width: calc(100vw - 900px);
  border-radius: 10px;
  margin-right: -25px;
}
@media screen and (max-width: 480px) and (min-width: 320px) {
  .EchartDisplay {
    width: 70px !important;
    margin-top: -30px !important;
  }
  .TotalSalesGraph {
    margin-left: -13px !important;
  }
  .Resp-AmtFont {
    font-size: 15px;
  }
  .Resp-Tital {
    font-size: 10.5px;
  }
}
@media only screen and (max-width: 320px) and (min-width: 320px) {
  .TotalSalesGraph {
    margin-left: 13px !important;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 768px) {
  .chartStyle {
    max-width: calc(100vw - 55vw);
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .chartStyle {
    padding: 0px 10px;
    height: 100%;
    max-width: calc(100vw - 155px) !important;
    border: none;
  }
}
