.inputBoxMb.ant-form-item {
  margin-bottom: 0 !important;
}
.inputBoxMb8.ant-form-item {
  margin-bottom: 5px !important;
}
/* .inputBoxMb .ant-form-item-label {
  padding-bottom: 0;
}
.inputBoxMb .ant-form-item-label>label {
    color: gray;
} */
.inputLabel33 .ant-form-item-label {
  padding-bottom: 0;
}
.inputLabel33 .ant-form-item-label > label {
  color: gray;
}

.addressdiv {
  color: lightgray;
  padding: 10px 0px 10px 13px;
  background-color: white;
  width: 40%;
  padding-top: 22px;
}

.input-sales.ant-form-item {
  margin-bottom: 8px !important;
}
.input-sales .ant-form-item-label {
  padding-bottom: 1px !important;
}
.input-sales-building.ant-form-item {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.input-sales-building .ant-form-item-label {
  padding-bottom: 0px !important;
}
.divider-sales {
  margin: 10px 18px;
  background: rgb(214, 214, 214);
}
.drawerTitle.ant-drawer .ant-drawer-title {
  font-size: 24px;
}

.tableForm11 {
  width: 100%;
  border-collapse: collapse;
}

.tableForm11 thead {
  background-color: rgb(248, 248, 248);
  color: rgb(11, 48, 142);
  margin-bottom: 10px !important;
  text-align: left;
}
.tableForm11 thead th {
  padding: 8px;
}

.tableForm11 tbody td {
  line-height: 0;
  padding: 2px 5px;
}

.inputBgColor {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(248, 248, 248);
}

.inputBgColor:hover {
  background-color: #fff !important;
}

.inputBgColor.ant-input-affix-wrapper > input.ant-input {
  background-color: rgb(248, 248, 248) !important;
}

.selectBgColor .ant-select-selector {
  background-color: rgb(248, 248, 248) !important;
  border: 1px solid rgb(248, 248, 248) !important;
}

.selectBgColor:hover .ant-select-selector {
  background-color: #fff !important;
  border-color: #4096ff !important;
}

.grayText {
  color: gray;
}
.darkBoldFont {
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.searchInputWidth .ant-select-selection-search-input {
  width: 266px !important;
}

.grayColor {
  color: gray !important;
}
.fs-12 {
  font-size: 12px;
}

.ca-addtab-setting {
}
