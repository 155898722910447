.labelsize .ant-form-item-label>label::after {
  margin-inline-start: -2px !important;
}

.fromdate .ant-picker-input {
  width: 95px !important;
}

.filterselectr .ant-select-selector {
  width: 90px !important;
}

.journaltab .ant-table thead tr th {
  color: rgb(11, 48, 142) !important;
}

.uploadname .ant-input-group .ant-input-group-addon {
  padding: 0px !important;
  /*border-radius: none !important;*/
  border-end-start-radius: 0px !important;
  border: 0px solid #d9d9d9 !important;
}

.journalmb .ant-form-item {
  margin-bottom: 5px !important;
}

.journaltotal.ant-divider-horizontal {
  margin: 15px !important;
}

.labelstyle {
  color: #0B308E !important;
}

.AddPageTabelDesignjournal {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.AddPageTabelDesignjournal thead {
  background-color: rgb(240, 245, 255);
  color: rgb(11, 48, 142);
  margin-bottom: 10px !important;
  /* text-align: left; */
}

.AddPageTabelDesignjournal thead th {
  border: 1px solid #e0dada;
  padding: 8px !important;
}

.AddPageTabelDesignjournal tbody td {
  /* line-height: 0; */
  /* padding: 1px 5px; */
}

.savejrnl.ant-btn-primary:disabled {
  width: 83px !important;
}

.savejrnl2.ant-btn-primary {
  width: 83px !important;
}

.cancelbtn.ant-btn-default {
  width: 83px !important;
}

.modelposition.ant-modal-wrap {
  bottom: 50px !important;
}