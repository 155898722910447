/*﻿@import url("https://fonts.googleapis.com/css2?family=REM:wght@400;500;600;700&display=swap");*/

* {
  margin: 0px;
  /* user-select: none; */
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: "Noto Sans", sans-serif; */
  font-family: "Inter", sans-serif;
}
.ant-menu-item:hover .add-icon-button {
  display: block;
}

.add-icon-button {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
/* ===================custom css for icon with background (Avatar)===============*/
.avatar-user {
  border: 2px solid #d1e6ff;
  background-color: #f4f8ff;
  cursor: pointer;
}

.avatar-user .anticon svg {
  margin-bottom: 1px;
}

.avatar-user .anticon {
  color: #8ec1ff !important;
}

.avatar-emp {
  background-color: rgb(242, 244, 251);
}

.avatar-emp .anticon {
  color: #c5d2ff;
}

.themeBgColor {
  background-color: #3d4998 !important;
}

.tablefont {
  color: #0b308e;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-3 {
  padding-left: 16px;
  padding-right: 16px;
}
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.text-overflowcls {
  text-overflow: ellipsis;
}
@keyframes myanimation {
  from {
    width: 100px;
  }

  to {
    width: 200px;
  }
}
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}
::-webkit-scrollbar-thumb {
  background-color: #ececec; /* Set the color of the scrollbar thumb */
}

/* ::-webkit-scrollbar-thumb:hover {
  background-color: #cbcbcb;
  /* Set the width of the scrollbar */
/* } */
.ant-table-wrapper .ant-table-tbody > tr > td {
  border-color: #eef2fe;
}

.myanim {
  animation-name: myanimation;
  animation-duration: 0.5s;
}
.ant-tabs-tab:hover {
  background-color: #f4f5f7;
}
.crossstyle {
  height: "80px";
}

.header {
  /* background-color: #3d4998 !important; */
  height: 45px !important;
  position: fixed;
  width: 100%;
  z-index: 1;
  padding: 0px !important;
}
table tr td {
  color: #444653;
}
.ant-input::placeholder {
  color: #80828d;
  /* font-weight: 500; */
}
.ant-picker-input input::placeholder {
  color: #80828d !important;
}
.ant-select-selection-placeholder {
  color: #80828d !important;
}
/* td:nth-child(3) {
  font-weight: 500;
  color: #395cd2 !important;
} */
/* 480ca8 */
.footerstyle.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  background-color: #f7f8fa !important;
}
.ant-table-footer {
  background-color: #f5f8ff !important;
  color: #444653 !important;
  font-weight: 600;
}
.Siderstyle {
  z-index: 2;
}
.shadow-navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}
.Menusiderstyle {
  position: fixed;
  top: 0px;
  height: 98vh;
  padding-bottom: 85px;
}
.ant-layout-footer {
  padding: 0px 0px !important;
}
.ant-menu-item-selected {
  background: #f5f8ff !important;
  /* background: linear-gradient(to right, #03effe, #0b308e) !important; */
  /* width: 200px !important; */
  border-right: 4px solid #3a5dd3;
  border-radius: 0 !important;
  font-weight: 600;
  /* margin-left: 0px !important; */
  color: #3a5dd3 !important;
  /* color:#44546F !important; */
  position: relative;
  overflow: hidden;
  /* margin: 0px !important; */
}
.ant-menu-item:hover {
  background: #f5f8ff !important;
}

/* .ant-menu .ant-menu-item-selected:before {
  content: "";
  position: absolute;
  height: 100px;
  width: 20px;
  box-shadow: 10px 10px 15px 20px white;
  filter: blur(1px);
  opacity: 0.9;
  top: -30px;
  transition: 0.7s;
  transform: rotate(-20deg) translateX(-60px);
} */
/* .ant-menu-item-selected:hover:before {
  transform: rotate(-20deg) translate(140px, 70px);
} */
.ant-menu .ant-menu-item {
  /* width: "100%" !important; */
  /* margin-left: 0px !important; */
  padding-inline: 0 !important;
  padding-left: 24px !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  /* width: 100%; */
  color: #44546f !important;
}

.sidercustom .ant-menu-item {
  height: 38px !important;
}

.tagclass {
  margin-top: 8px;
  padding: 1px;
  text-align: center;
  width: 70px;
}

.tagclass1 {
  margin-top: 8px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  padding: 1.5px !important;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f5f5f5 !important;
}
/* ::-webkit-scrollbar {
  width: 0px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: transparent !important;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 12px;
  background: #a6a6a6;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #a6a6a681; */
/* background: rgb(217, 231, 228); */
/* } */

.custom .ant-table thead tr th {
  background-color: #f7f8fa !important;
}

.clientHover:hover {
  background-color: #f5f8ff;
}

/* .ant-select-selector {
  border: 2px solid #f1f3ff !important;
} */
/* .Tabstyle, */
.chartdivstyle {
  /* box-shadow: inset 1px 1px 12px 3px rgba(216, 220, 230, 1); */
}

.ant-layout .ant-layout-sider-trigger {
  background-color: lightgray;
}

/* creditnoteCss/ */

.contactboxparent {
  border: 1px dashed lightslategray;
}
.ant-tabs-tab-btn span {
  padding-top: 15px !important;
  padding-bottom: 18px !important;
}
.contactbox {
  padding: 25px;
  display: flex;
}

.inputboxparent {
  width: 500px;
}

.mycustombox .ant-select-selector {
  border: none !important;
}

.addressdiv {
  color: lightgray;
  padding: 10px 0px 10px 13px;
  background-color: white;
  width: 40%;
  padding-top: 22px;
}

.boxborder {
  border: 1px dashed lightslategray;
  padding: 25px;
}

.textcolor {
  color: rgb(11, 48, 142);
  font-weight: 500;
}

.datetext {
  color: rgb(11, 48, 142);
  font-weight: 500;
}

.checkBoxBgColor .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3d4998 !important;
  border-color: #3d4998 !important;
}

/*shareholderCss*/
.shareholder-form-item {
  color: #778899;
}

.shareholder-form-item:hover {
  background-color: whitesmoke;
}

.divider-m0 {
  margin: 0;
}
.edit .ant-form-item {
  margin-bottom: 10px !important;
}

.cpp-pagination .ant-select-selection-search {
  display: none !important;
}

.cpp-pagination .ant-select-arrow {
  display: none !important;
}

/* balancesheetcss */
.balancesheet-tablestyle {
  display: flex;
  justify-content: space-around;
}

.chart.ant-card .ant-card-body {
  padding: 8px !important;
  height: 0px !important;
}

.cusomcard.ant-card .ant-card-body {
  padding: 20px !important;
}

.cusomcard3.ant-card .ant-card-body {
  padding: 16px !important;
}
.TableCard.ant-card .ant-card-body {
  padding: 12px !important;
}
.customcard2.ant-card .ant-card-body {
  padding: 13px 0px 0px 12px !important;
}

.cashflow.ant-card .ant-card-body {
  padding: 13px !important;
  padding-left: 20px !important;
}

.schartpadding.ant-card .ant-card-body {
  margin-bottom: -18px !important;
}

/* -------- Mobile view Drawer Css  --------*/
.drawerToggle .ant-drawer-body {
  padding: 0px !important;
}

.drawerToggle .ant-drawer-header {
  padding: 0px !important;
}

.customcss .ant-collapse-header {
  display: none !important;
}

.customcssColl .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.transparentbg2 {
  animation-name: myanimj2;
  animation-duration: 1s;
  height: 250px;
}

.customtext.ant-typography {
  margin-bottom: -10px;
}

.set-pt.ant-card .ant-card-body {
  padding: 14px;
}
/* -------------  Theme css ------------- */
.ca-card-theme .ant-card-body {
  padding: 0;
}

.ca-theme-colorPicker .ant-color-picker-color-block {
  width: 80px;
  height: 70px;
}

/* ----- SalesInvoiceTemplate cpn Tab css(sales, purchase & other Top header tab) -----*/
.ca-header-tab .ant-tabs-nav::before {
  /* border-bottom: none; */
}
/* --------- SalesInvoiceTemplate cpn Tab hover css(Top header tab) ---------- */
.ant-tabs-tab .ca-hover-show {
  /* visibility: hidden; */
  opacity: 0 !important;
}
.ant-tabs-tab .ca-hover-show:hover {
  /* display: inline-block; */
  opacity: 1 !important;
  /* padding-right: 10px; */
}

.ant-tabs-tab-active .ca-hover-show {
  opacity: 1 !important;
}

.ca-header-tab .ant-tabs-tab-active {
  /* padding: 0 15px 0px 0px !important; */
  /* margin: 0 0px 0 25px !important; */
}

/* .ca-header-tab .ant-tabs-tab {
  margin: 0 0 0 20px !important;
} */

/* ------------- Quick Add Css ------------- */
.ant-btn.ca-quickadd-btn {
  padding: 1px 6px;
}

.ca-float-btn .ant-float-btn-group .ant-float-btn:not(:last-child) {
  margin-bottom: 1px !important;
}
