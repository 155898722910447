.labelclr {
    color: rgb(120, 120, 120) !important;
}

.inputboxsize {
    width: 135px !important;
}

.inputboxmb.ant-form-item {
    margin-bottom: 7px !important;
}

.ant-form-item-control-input-content {
    max-width: 102%;
}

.filetype .ant-input-group-addon {
    border: none !important
}

.botomline.ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 10px 0 !important;
}

.ant-form-item-control-input-content {
    flex: auto !important;
    max-width: 100% !important;
}

.modelpadding .ant-modal-content {
    padding: 13px 17px !important;
}

.tablestyle .ant-table thead tr th {
    color: rgb(11, 48, 142) !important;
}

.bcolor .ant-table thead tr th {
    background-color: lightgray !important;
}

.modeldate .ant-picker-dropdown {
    z-index: 77789889 !important;
}

.ant-form-item-label {
    padding: 1px !important;
}

.fontcolor {
    color: #787878 !important;
}

.ant-form-item .ant-form-item-explain-error {
    color: #ff4d4f !important;
    font-size: 11px !important;
}