.mtmb.ant-divider-horizontal{
    margin: 6px !important;
}
.mb.ant-form-item{
    margin-bottom:14px !important;
}
.ftmb .ant-modal-footer{
    margin-top: 0px !important;
}
.ml {
    margin-left: 26px !important;
}
.ExpAddPageTabelDesign {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse !important;
}

.ExpAddPageTabelDesign thead {
    background-color: rgb(240, 245, 255);
    color: rgb(11, 48, 142);
    margin-bottom: 10px !important;
    padding-left: 20px !important;
   }

.ExpAddPageTabelDesign thead th {
    padding: 5px;
    border: 1px solid #e0dada;
    padding-left: 12px;
}

.ExpAddPageTabelDesign tbody td {
   padding-top: 2px;
}
