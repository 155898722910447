/* common text and font */
.textfont {
  color: rgb(11, 48, 142);
  font-weight: 500;
}

.p-10 {
  padding: 10px;
}

.pe-31 {
  padding-right: 30px !important;
}

.pe-35 {
  padding-right: 35px !important;
}

.pe-30 {
  padding-right: 20px !important;
}

.ps-31 {
  padding-left: 27px !important;
}

.ps-42 {
  padding-left: 42px !important;
}

.ps-23 {
  padding-left: 23px !important;
}

.textCenter {
  text-align: center;
}

.cursor {
  cursor: pointer;
}

/* Invoices common  css */
.mainbox {
  max-width: 97%;
}

.dashedBoxStyle {
  border: 1px dashed rgb(203, 203, 203);
  padding: 10px;
}

.dashedBorderTop {
  border-bottom: 1px dashed rgb(162, 160, 160);
  padding-bottom: 3px;
}
.dashedBorderTop td {
  border: none !important;
  font-weight: bold;
}

.grayBackGround {
  background-color: rgb(248, 248, 248);
}

.titleDesign {
  font-size: 30px;
  font-weight: 400;
  color: rgb(11, 48, 142);
  text-align: end;
}

.invoice-sec2 {
  margin: 6px 0;
}

.tabletopmargin {
  margin-top: 15px;
}

.DividerStyle1 {
  border: 1px dashed lightgray;
}

.TotalBoxstyle {
  margin-left: 252px;
  text-align: end;
}

.TotalBoxstyle2 {
  border: 1px solid gray;
  width: 300px;
  margin-left: 120px;
  border-left: none;
  border-right: none;
  padding: 15px 0px;
}

.tableWrapper {
  max-height: 329px;
  overflow-y: scroll;
  /* margin-top: 20px; */
}

.AddPageTabelDesign {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

/* .unCheckSwitch :not(.ant-switch-disabled) {
  background: rgb(11, 48, 142);
} */
.AddPageTabelDesign thead {
  z-index: 2;
  top: 0px;
  position: sticky;
  background-color: rgb(247, 248, 250);
  color: black;
  margin-bottom: 10px !important;
}

.AddPageTabelDesign tfoot {
  z-index: 2;
  bottom: 0px;
  position: sticky;
  background-color: #fff;
}

.AddPageTabelDesign thead th {
  border: 1px solid #e0dada;
  font-weight: 500;
}

.AddPageTabelDesigngst thead th {
  border: 1px solid #e0dada;
}

.AddPageTabelDesign tbody {
  z-index: 1;
}

.AddPageTabelDesign tbody td {
  padding: 2px 2px;
  /* border: 1px solid #e0dada; */
  border: 1px solid #d9d9d9;
}

.AddPageTabelDesignExpenses {
  width: 100%;
}

.AddPageTabelDesignExpenses tbody td {
  text-align: start !important;
}

/* templete table Css */

.templateTableDesign thead {
  background-color: #f3f8ff !important;
  text-align: start !important;
}

.templateTableDesign tbody tr td {
  text-align: start !important;
}

.border {
  border-bottom: 1px solid #f0f0f0 !important;
}

/*------ ViewModel Css ( Sales Invoice Model ) ------ */

.tbl-customerinfo {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}
.tbl-customerinfo tr td {
  vertical-align: top;
}

.tbl-customerinfo tr {
  line-height: 1.25;
}
.ca-viewmodel-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.ca-viewmodel-table thead {
  background-color: rgb(247, 248, 250);
  color: rgb(11, 48, 142);
  margin-bottom: 10px !important;
  /* text-align: left; */
}

.ca-viewmodel-table thead th {
  border: 1px solid #e0dada;
  padding: 2px 4px;
}

.ca-viewmodel-table tbody td {
  /* line-height: 0; */
  padding: 2px 4px;
  border: 1px solid #e0dada;
  /* text-align: left; */
}

.ca-viewmodel-table td {
  border-bottom: 1px solid #ddd;
}

.ca-viewmodel-table tr:hover {
  background-color: rgb(247, 248, 250);
}

/* ca-payment-model */
.ca-payment-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.ca-payment-table thead {
  background-color: rgb(247, 248, 250);
  color: rgb(11, 48, 142);
  margin-bottom: 10px !important;
  /* text-align: left; */
}

.ca-payment-table thead th {
  border: 1px solid #e0dada;
  /* padding: 8px; */
}

.ca-payment-table td {
  border-bottom: 1px solid #ddd;
}

.ca-payment-table .last-row {
  border-bottom: 2px solid #787878;
  /* border-bottom: none; */
}

.ca-payment-table tr:hover {
  background-color: rgb(247, 248, 250);
}

.inputBoxMb5.ant-form-item {
  margin-bottom: 5px !important;
}

.inputBoxMb.ant-form-item {
  margin-bottom: 0 !important;
}

.inputBoxMb8.ant-form-item {
  margin-bottom: 8px !important;
}

.errormsg .ant-form-item-explain-error {
  /* padding-left: 12px !important; */
  /* color: rgb(164, 38, 44); */
  font-size: 12px;
}

.selectBorder .ant-select-selector {
  border: 1px solid #fff !important;
}

.selectedItem .ant-select-single .ant-select-selector {
  font-weight: "bold";
}

.selectPlaceholder .ant-select-selection-placeholder {
  color: #9f9797;
  /* font-size: 17px; */
}

.selectPlaceholder-2 .ant-select-selection-placeholder {
  /* color: rgba(0, 0, 0, 0.88) !important; */
  color: black;
}

.inputNumberTextEnd .ant-input-number-input {
  text-align: end;
}

.popoverStyle {
  align-items: center;
  background-color: #fff;
  height: 22px;
  width: auto;
  border: 1px solid #d3d3d3;
  border-radius: 100%;
  padding: 2px;
  margin-left: 8px;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .fs-35 {
    font-size: 18px;
  }
}

/* Model Css */

.ca-model-css.ant-divider-horizontal {
  margin: 0px 6px 13px 6px !important;
}

.tabFlexOverride .ant-tabs-nav .ant-tabs-nav-wrap {
  flex: none;
}

/* notification css */

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  font-size: 16px;
  align-items: center;
  height: 16px;
  width: 0px;
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.88);
  /* background-color: rgba(0, 0, 0, 0.06) !important; */
}

/* Ledger drawer */
/* .ant-drawer .ant-drawer-body {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
} */

.variantFilledSelect .ant-select-selector {
  background-color: #ffffff !important;
}

.variantFilledSelectDisable .ant-select-selector {
  background-color: #f0f8ff !important;
}

.modelSaveButtonMargin {
  margin-left: 10px;
}
