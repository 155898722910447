/*--------- purItem ---------*/
.input-item .ant-form-item-label > label {
  /* width: 160px !important; */
}
@media screen and (max-width: 545px) {
  .input-item.ant-form-item {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 545px) {
  .input-item.ant-form-item {
    margin-bottom: 14px !important;
  }
}

/* .input-item.ant-form-item {
  margin-bottom: 12px !important;
} */

.input-item .ant-form-item-label {
  padding-bottom: 0px !important;
}

.input-item1.ant-form-item {
  margin-bottom: 0px !important;
}
.input-purchase.ant-form-item {
  margin-bottom: 10px !important;
}
.input-purchase .ant-form-item-label {
  padding-bottom: 1px !important;
}
.input-purchase-building.ant-form-item {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.input-purchase-building .ant-form-item-label {
  padding-bottom: 0px !important;
}
/* .divider-purchase {
  margin: 20px 0px;
} */
.ca-tab-addItem .ant-tabs-nav::before {
  border-bottom: none;
}
.ca-tab-addItem .ant-tabs-nav {
  margin-bottom: 4px !important;
}

/* @media screen and (min-width: 380px) and (max-width: 575px) {
  :where(.css-dev-only-do-not-override-jk3ekw).ant-form
    .ant-form-item
    .ant-form-item-label {
    flex: 0 0 100%;
    max-width: 50% !important;
  }
}

@media screen and (min-width: 380px) and (max-width: 575px) {
  :where(.css-dev-only-do-not-override-jk3ekw).ant-form
    .ant-form-item
    .ant-form-item-label,
  :where(.css-dev-only-do-not-override-jk3ekw).ant-form
    .ant-form-item
    .ant-form-item-control {
    max-width: 50% !important;
  }
} */

/* --------- purchase-payment --------- */
.input-purchase-payment .ant-form-item-label > label {
  font-weight: 500;
  color: #0b308e !important;
}
.input-purchase-payment.ant-form-item {
  margin-bottom: 8px !important;
}
.input-purchase-payment .ant-form-item-label {
  padding-bottom: 1px !important;
}

.divider-purchase.ant-divider-horizontal {
  margin: 10px 0px !important;
}
/*--------- purchase-order --------- */
.input-purchase-order .ant-form-item-label {
  padding-bottom: 1px !important;
}
.input-purchase-order .ant-form-item-label > label {
  font-weight: 500;
  color: #0b308e !important;
}
.dropDown-purchase {
  height: 32px !important;
  width: 70px !important;
}



/*  --------- borderLessInput chartOfAccounts--------- */
.borderLessInput {
  border: none;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px;
}
/* .input-account .ant-form-item-label > label {
  width: 100px !important;
} */
.input-account.ant-form-item {
  margin-bottom: 7px !important;
}
.input-account :where(.css-dev-only-do-not-override-1553uae).ant-input:focus,
:where(.css-dev-only-do-not-override-1553uae).ant-input-focused {
  /* border-color: #f50000; */
  box-shadow: 0 0 0 2px rgb(255, 255, 255);
  border-bottom: 1px solid #3d4998 !important;
  border-inline-end-width: 1px;
  outline: 0;
}
.divider-account {
  margin: 10px 0px 15px 0px;
}


/*  Table-Head-Top-level */

/* .ant-table-thead thead th {
  position: fixed !important;
  z-index: 1;
  width: 100%;  
} */

 /* .ant-table-thead {
  position: sticky !important;
  top: 0;
  z-index: 1;
} */

/* .ant-table-tbody {
  overflow-y: scroll !important;
  height: calc(100% - 40px);
}  */

/* .ant-table-tbody {
  overflow-y: scroll !important;
  height: calc(100% - 40px);
}  */