
.partydrawer .ant-drawer-header {
  padding: 12px 18px !important;
}

.pb-5 {
  padding-bottom: 10px !important;
}

.paddingdrawer .ant-drawer-body {
  padding-top: 10px !important;
  padding: 18px !important;
}

.ant-btn.imgupload {
  padding: 4px 9px !important;
}

.ant-upload-list-item-name.imgpreview {
  position: absolute !important;
}

.ant-divider-horizontal.ant-divider-with-text .ca-dividertext {
  margin: 6px !important;
}

.dividerclr {
  color: rgb(253 250 250) !important;
}

/* .ant-form label {
  margin-bottom: 5px !important;
} */


.mleft {
  margin-left: 8px !important;
}

.pb {
  padding-bottom: 11px !important;
}

.ca-dividertext .ant-divider-inner-text {
  padding-right: 30px !important;
}

.Errorname .ant-form-item-explain-error {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.ca-active-table {
  background-color: #cee6f3;
}

.ca-tab-party .ant-tabs-nav {
  margin: 0px !important;
}

.ca-card-body .ant-card-body {
  padding: 0 !important;
  
  /*box-shadow: border-box !important;*/
}

.ca-card-body-padding .ant-card-body {
  padding: 3px 8px !important;
  /*box-shadow: border-box !important;*/
}

/* .ant-upload-list-item {
  position: absolute !important;
} */

.ca-cashInHand-card-body {
  padding: 4px 11px !important;
  background-color: #fafafa;
  
}

.ca-cashInHand-card-body .ant-card-body {
  padding: 0 !important;
}

#collapseparty {
  background-color: gainsboro !important;
  height: 10% !important;
}

.withoutanimation {
  height: calc(100vh - 145px) !important;
}

.ant-checkbox + span {
  padding-left: 3px !important;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.partydetailsanimation {
  height: 8px !important;
  position: relative !important;
  animation-name: slidein !important;
  animation-iteration-count: infinite !important;
  animation-direction: alternate !important;
  animation-duration: 12s;
}

@keyframes slidein {
  from {
    width: 10%;
  }

  to {
    width: 98%;
  }
}

.partyanimationoverview {
  position: relative !important;
  animation-name: slideinoverview !important;
  transition-timing-function: ease-out !important;
  animation-direction: reverse !important;
  animation-direction: alternate !important;
  animation-duration: 1100ms;
}

@keyframes slideinoverview {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
