/*----- css login page------*/
/*input:-webkit-autofill {
    -webkit-background-clip: text;
}
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap");

.mainloginheading {
  font-size: 30px;
  color: #214e9b;
  margin-bottom: 15px;
}

.secondloginheading {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: bold;
}

.thirdloginheading {
  font-weight: normal;
  font-size: 17px;
  margin-bottom: 25px;
}

.loginnames {
  color: #4d4d4d;
  height: 20px;
  line-height: 0.8;
  font-size: 16px;
}

.lgname {
  color: #707070;
}

.loginBox {
  width: 420px;
  height: 100%;
  /* background-color: #f9faff; */
  margin: auto 192px auto auto;
  /* border: 2px solid #e8ecff; */
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  /* box-shadow: 2px 2px 10px 4px rgba(100, 100, 100, 0.2); */
}
.loginImg {
  width: 480px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginInnerBox {
  padding: 0px 38px 0px 38px;
}

/* .loginImg {
    padding: 0px 0px 0px 0px;
} */

.loginBoxSection {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

/* .images{
    width: 40% !important;
    height: 30% !important;
} */

.textanimation {
  overflow: hidden !important;
  position: absolute !important;
  padding-left: 0.5rem !important;
  top: 0.2rem !important;
  left: 270px !important;
  animation: openclose 10s ease-in-out infinite !important;
}

.h100vh {
  height: 100vh;
}

.headerprofile.ant-divider-horizontal {
  margin: 0px !important;
}

.linkcolor {
  color: #377dff;
}

/*----- responsive css login page------*/

@media (max-width: 1300px) {
  .loginBox {
    margin: auto;
  }
}

@media (max-width: 991px) {
  .loginImg {
    display: none;
  }
}

@media (max-width: 450px) {
  .loginInnerBox {
    padding: 18px;
  }

  .loginBox {
    width: 280px;
    height: 400px;
  }

  .ant-checkbox + span {
    padding-inline-end: 0px;
  }
}

/*-----ant login form custom css ------*/

.loginInput {
  height: 30px;
  padding: 1px 10px 1px 1px !important;
}

.login-form .ant-form-item {
  margin-bottom: 18px;
}

.loginInput {
  border: none;
  outline: none;
  background-color: #f9faff;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0px !important;
}

.loginInput input {
  border: none;
  outline: none;
  background-color: #f9faff;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0px !important;
}

.loginInput:focus {
  border-bottom: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
